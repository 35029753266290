import "./demo.css";
import "./../App.css";
import Verify from "../components/Verify";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
// import { AiOutlineSchedule} from "react-icons/ai";
// import { MdOutlineAlternateEmail } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { IoCloseCircleSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import Bsignature from "../components/Bsignature";
import Static from "../components/Static";

const Demo = () => {
  const [val, setVal] = useState(1);
  const form = useRef();
  const [buyer, setBuyer] = useState("");
  const [seller, setSeller] = useState("");
  const [propertyAddress, setPropertyAddress] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [earnMoney, setEarnMoney] = useState("");
  const [cclosingDate, setCclosingDate] = useState("");
  const [financing, setFinancing] = useState("");
  const [deligencePeriod, setDeligencePeriod] = useState("60 days");
  const [other, setOther] = useState("");
  const [buyerPrient, setBuyerPrient] = useState("");
  const [sellerPrient, setSellerPrient] = useState("");
  const [buyerSignature, setBuyerSignature] = useState("");
  const [bdate, setBdate] = useState("");
  const [logo, setLogo] = useState("");
  const [addClause, setAddClause] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [noLogo, setNologo] = useState("No");
  //   -----------Show---------------------->
  const [bshow, setbShow] = useState(false);

  //   ---------|URL|---------------->
  const [burl, setBurl] = useState("");
  // const [surl, setSurl] = useState("");

  //   <-----------------------Validation--------------------->
  const handleForm = () => {
    // if (
    //   !buyer ||
    //   !seller ||
    //   !propertyAddress ||
    //   !purchasePrice ||
    //   !earnMoney ||
    //   !cclosingDate ||
    //   !financing ||
    //   !email ||
    //   !other ||
    //   !sellerPrient ||
    //   !burl ||
    //   !phone ||
    //   !bdate
    // ) {
    //   toast.success("Please Fill all fields!", {
    //     position: "top-center",
    //     theme: "dark",
    //   });
    // } else {
    setVal(3);
    // }
  };

  // --------------Handle Signature------------->
  // const handlebSignature = () => {

  //   setbShow(true);
  // };

  //   getURL
  const handleSignature = () => {
    setBurl(buyerSignature.getTrimmedCanvas().toDataURL("image/png"));
  };

  // const shandleSignature = () => {
  //   setSurl(sellerSignature.getTrimmedCanvas().toDataURL("image/png"));
  // };

  //   ------------------------->Get Logo Image----------------------->
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = (event) => {
  //       setLogo(event.target.result);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 35 * 1024) {
        toast.error("Logo size should be 35 KB or less.", {
          position: "top-center",
        });
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        setLogo(event.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  // "service_s05eof7",
  // "template_1jwb0rz",
  // "84gCo8B9Pspq6SIat"
  // ---------------------------Form Submission------------------->

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_s05eof7",
        "template_1jwb0rz",
        form.current,
        "84gCo8B9Pspq6SIat"
      )
      .then(
        (result) => {
          toast.success("Form has been submitted successfully!", {
            theme: "dark",
            position: "top-center",
          });

          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          toast.success("Something went wrong!", {
            theme: "light",
            position: "top-center",
          });
        }
      );
  };

  return (
    <>
      <div
        lang="en"
        tabindex="-1"
        class="ðŸ˜‰ cog-cognito cog-form cog-1 is-default cog-cognito--styled cog-form--light-background cog-form--maximal-branding"
        data-width="800 700 650 625 600 575 550 525 500 475 450 425 400 375 350 325 300 275 250 225 200"
        data-stretch=""
      >
        <div
          class="cog-form__container"
          id="main_content"
          style={{ overflow: "hidden" }}
        >
          <div class="cog-form__content">
            <header class="cog-header cog-wrapper cog-header--row cog-header--left">
              <div class="cog-header__text">
                <h1 class="cog-heading">
                  LETTER OF INTENT TO PURCHASE REAL ESTATE
                </h1>
                <div
                  class="cog-header__description"
                  style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                ></div>
              </div>
            </header>
            <div className="schedule"></div>
            <a
              href="https://tidycal.com/schelp/loi"
              target="blank"
              style={{ textDecoration: "none" }}
              className="bookbtn"
            >
              Book Appointment
            </a>

            <div
              class="cog-page-progress cog-page-progress--bar"
              data-backwards="true"
            >
              <div class="el-scrollbar">
                <div
                  class="el-scrollbar__wrap"
                  style={{ marginBottom: "-17px", marginRight: "-17px;" }}
                >
                  <ol
                    class="el-scrollbar__view tab"
                    style={{ paddingTop: "62px" }}
                  >
                    <li
                      data-title="Spouse and Dependents "
                      class="cog-page-progress__page"
                    >
                      <a
                        id="tab2"
                        class={
                          val === 1
                            ? "tablinks toptab active"
                            : "tablinks toptab"
                        }
                        onClick={() => setVal(1)}
                        data-page-number="1"
                      >
                        <span class="cog-page-progress__page-title">
                          Reference Form{" "}
                        </span>
                      </a>
                    </li>
                    <li
                      data-title="Taxpayer Information"
                      class="cog-page-progress__page is-active"
                    >
                      <a
                        id="tab1"
                        class={
                          val === 2
                            ? "tablinks toptab active"
                            : "tablinks toptab"
                        }
                        onClick={() => setVal(2)}
                        data-page-number="2"
                      >
                        <span class="cog-page-progress__page-title">
                          Intake Form
                        </span>
                      </a>
                    </li>
                    <li
                      data-title="Spouse and Dependents "
                      class="cog-page-progress__page"
                    >
                      <a
                        id="tab2"
                        class={
                          val === 3
                            ? "tablinks toptab active"
                            : "tablinks toptab"
                        }
                        onClick={() => setVal(3)}
                        data-page-number="3"
                      >
                        <span class="cog-page-progress__page-title">
                          Verification{" "}
                        </span>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div class="cog-body" id="cog-body">
              {/* <!--tab data 1--> */}
              {
                <div
                  // data-page="1"
                  class="cog-page cog-wrapper cog-transition-ltr tabcontent"
                  id="London"
                  style={{ display: val === 2 ? "block" : "none" }}
                >
                  <div class="cog-row first">
                    <section
                      aria-labelledby="cog-56"
                      tabindex="-1"
                      style={{ animation: "varify 1s linear" }}
                      class="cog-section cog-col cog-col--24 cog-section--heading cog-field--7"
                    >
                      <div class="cog-section__inner cog-section__inner--no-background ">
                        <form ref={form} onSubmit={sendEmail}>
                          {/* --------------Logo-------------- */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                width: "14rem",
                                height: "10rem",
                                border: "2px dotted orangered",
                                overflow: "hidden",
                                Object: "fill",
                                borderRadius: ".3rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="file"
                                required
                                id="logo"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                              {logo ? (
                                <>
                                  <img
                                    src={logo}
                                    value={logo}
                                    alt="Selected Logo"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                  <IoCloseCircleSharp
                                    className="close"
                                    onClick={() => setLogo("")}
                                  />
                                  <input
                                    type="text"
                                    style={{ display: "none" }}
                                    name="logo"
                                    value={logo}
                                  />
                                </>
                              ) : (
                                <label
                                  htmlFor="logo"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    color: "#111",
                                    cursor: "pointer",
                                  }}
                                >
                                  <AiOutlinePlus
                                    className="logoIcon"
                                    size={25}
                                    color="orange"
                                  />
                                  Add Custom Logo
                                </label>
                              )}
                            </div>
                            <label
                              htmlFor="Nologo"
                              style={{
                                fontSize: "1rem",
                                marginTop: "1.2rem",
                                fontWeight: "500",
                                color: "#111",
                              }}
                            >
                              <input
                                type="checkbox"
                                id="Nologo"
                                name="nologo"
                                value={noLogo}
                                onChange={(e) => setNologo(e.target.value)}
                                onClick={() => setLogo("Yes")}
                              />{" "}
                              No logo
                            </label>
                          </div>

                          <div class="cog-row">
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                Buyer
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    required
                                    name="buyer"
                                    value={buyer}
                                    onChange={(e) => setBuyer(e.target.value)}
                                    placeholder=""
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                Seller
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    required
                                    name="seller"
                                    value={seller}
                                    onChange={(e) => setSeller(e.target.value)}
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="cog-row" style={{ color: "black" }}>
                            This is a letter of intent only. It does not create
                            a legally binding obligation on the parties or their
                            brokers. This letter of intent is subject to
                            agreement between the parties upon a formal written
                            purchase and sale contract containing the terms and
                            conditions the parties find acceptable. This letter
                            of intent sets forth some of the general terms and
                            conditions for a purchase and sale transaction to be
                            entered into concerning the above described real
                            property.
                          </div>

                          <div className="cog-row">
                            <div
                              class="cog-field cog-field--7-2 cog-col cog-col--8 cog-phone cog-phone--us"
                              style={{ width: "100%" }}
                            >
                              <label
                                id="cog-61-label"
                                for="cog-61"
                                class="cog-label"
                              >
                                PROPERTY ADDRESS City, State, Zipcode
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    required
                                    name="propertyAddress"
                                    value={propertyAddress}
                                    onChange={(e) =>
                                      setPropertyAddress(e.target.value)
                                    }
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cog-row">
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                PURCHASE PRICE (in USD with $)
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="number"
                                    required
                                    name="purchasePrice"
                                    value={purchasePrice}
                                    onChange={(e) =>
                                      setPurchasePrice(e.target.value)
                                    }
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                EARNEST MONEY (in USD with $)
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="number"
                                    // placeholder="1% at completion of Due Diligence"
                                    required
                                    name="earnMoney"
                                    value={earnMoney}
                                    onChange={(e) =>
                                      setEarnMoney(e.target.value)
                                    }
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cog-row">
                            <div class="cog-field cog-field--7-36 cog-col cog-col--12 cog-choice cog-choice--dropdown">
                              <label
                                id="cog-59-label"
                                for="cog-59"
                                class="cog-label"
                              >
                                FINANCING Period
                              </label>
                              <div class="cog-choice">
                                <div class="cog-picker cog-select cog-input">
                                  <div class="el-select el-select--dropdown-bottom">
                                    <div class="el-input el-input--prefix el-input--suffix">
                                      <select
                                        required
                                        placeholder="45 days from completion of Due Diligence"
                                        name="financing"
                                        value={financing}
                                        onChange={(e) =>
                                          setFinancing(e.target.value)
                                        }
                                        style={{
                                          width: "100%",
                                          height: "37px",
                                          borderColor: "#e5e5e5",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <option value="30 Days">30 Days</option>
                                        <option value="45 Days">45 Days</option>
                                        <option value="60 Days">60 Days</option>
                                        <option value="90 Days">90 Days</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div class="cog-choice__placeholder cog-input">
                                  <div class="cog-field__placeholder-inner">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                            <div class="cog-field cog-field--7-36 cog-col cog-col--12 cog-choice cog-choice--dropdown">
                              <label
                                id="cog-65-label"
                                for="cog-65"
                                class="cog-label"
                              >
                                CONTRACT CLOSING DATE
                              </label>
                              <div class="cog-picker cog-input">
                                <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date">
                                  <input
                                    type="date"
                                    id="closingDate"
                                    required
                                    name="cclosingDate"
                                    value={cclosingDate}
                                    onChange={(e) =>
                                      setCclosingDate(e.target.value)
                                    }
                                    class="el-input__inner date_inpt"
                                  />
                                  <span class="el-input__suffix ">
                                    <span class="el-input__suffix-inner">
                                      <i class="el-input__icon"></i>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="cog-row">
                            <div
                              class="cog-field cog-field--7-2 cog-col cog-col--8 cog-phone cog-phone--us"
                              style={{ width: "100%" }}
                            >
                              <label
                                id="cog-61-label"
                                for="cog-61"
                                class="cog-label"
                              >
                                ADDITIONAL CLAUSES
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    required
                                    name="addClause"
                                    value={addClause}
                                    onChange={(e) =>
                                      setAddClause(e.target.value)
                                    }
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cog-row">
                            <div
                              class="cog-field cog-field--7-2 cog-col cog-col--8 cog-phone cog-phone--us"
                              style={{ width: "100%" }}
                            >
                              <label
                                id="cog-61-label"
                                for="cog-61"
                                class="cog-label"
                              >
                                DELIGENCE PERIOD
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    placeholder="60 days from receipt of all available,
                                    requested Due Diligence materials."
                                    required
                                    name="deligencePeriod"
                                    value={deligencePeriod}
                                    onChange={(e) =>
                                      setDeligencePeriod(e.target.value)
                                    }
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cog-row">
                            <div
                              class="cog-field cog-field--7-2 cog-col cog-col--8 cog-phone cog-phone--us"
                              style={{ width: "100%" }}
                            >
                              <label
                                id="cog-61-label"
                                for="cog-61"
                                class="cog-label"
                              >
                                Insert any deal specific clause etc here
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    required
                                    name="other"
                                    value={other}
                                    onChange={(e) => setOther(e.target.value)}
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cog-row">
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                Buyer Print
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    required
                                    name="buyerPrient"
                                    value={buyerPrient}
                                    onChange={(e) =>
                                      setBuyerPrient(e.target.value)
                                    }
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                Seller Print
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="text"
                                    required
                                    name="sellerPrient"
                                    value={sellerPrient}
                                    onChange={(e) =>
                                      setSellerPrient(e.target.value)
                                    }
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* -----------------Signature BOX---------------- */}

                          <div
                            className="prints"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              userSelect: "none",
                            }}
                          >
                            {bshow ? (
                              <>
                                <Bsignature
                                  setBuyerSignature={setBuyerSignature}
                                  buyerSignature={buyerSignature}
                                  setbShow={setbShow}
                                  handleSignature={handleSignature}
                                />
                              </>
                            ) : (
                              ""
                            )}
                            {burl ? (
                              <div
                                className="urlimage"
                                style={{ position: "relative" }}
                              >
                                <img
                                  src={burl}
                                  alt="..."
                                  value={burl}
                                  className="imageURL"
                                />
                                <IoCloseCircleSharp
                                  className="close"
                                  onClick={() => setBurl("")}
                                />
                                <input
                                  type="text"
                                  style={{ display: "none" }}
                                  name="burl"
                                  value={burl}
                                />
                                <button
                                  type="button"
                                  onClick={() => setbShow(true)}
                                  className="signature"
                                >
                                  Buyer Signature
                                </button>
                              </div>
                            ) : (
                              // <button
                              //   type="button"
                              //   onClick={() => setbShow(true)}
                              //   style={{ margin: ".5rem 0rem" }}
                              //   className="signature"
                              // >
                              //   Buyer Signature
                              // </button>
                              <div>
                                <input
                                  type="button"
                                  className="signature"
                                  onClick={() => setbShow(true)}
                                  style={{ margin: ".5rem 0rem" }}
                                  value="Buyer Signature"
                                />
                              </div>
                            )}
                          </div>
                          {/* -----------------Signature BOX---------------- */}

                          <div class="cog-row">
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-65-label"
                                for="cog-65"
                                class="cog-label"
                              >
                                DATE
                              </label>
                              <div class="cog-picker cog-input">
                                <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date">
                                  <input
                                    type="date"
                                    required
                                    name="bdate"
                                    value={bdate}
                                    onChange={(e) => setBdate(e.target.value)}
                                    class="el-input__inner date_inpt"
                                  />
                                  <span class="el-input__suffix ">
                                    <span class="el-input__suffix-inner">
                                      <i class="el-input__icon"></i>
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                            {/* <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-65-label"
                                for="cog-65"
                                class="cog-label"
                              >
                                DATE
                              </label>
                              <div class="cog-picker cog-input">
                                <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date">
                                  <input
                                    type="date"
                                    required
                                    name="sdate"
                                    value={sdate}
                                    onChange={(e) => setSdate(e.target.value)}
                                    class="el-input__inner date_inpt"
                                  />
                                  <span class="el-input__suffix ">
                                    <span class="el-input__suffix-inner">
                                      <i class="el-input__icon"></i>
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div class="cog-row">
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                Your Email
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="email"
                                    required
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                            <div class="cog-field cog-field--7-35 cog-col cog-col--8 cog-text cog-text--singleline">
                              <label
                                id="cog-57-label"
                                for="cog-57"
                                class="cog-label"
                              >
                                Your Phone
                              </label>
                              <div class="cog-input">
                                <div class="el-input">
                                  <input
                                    type="number"
                                    required
                                    name="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    class="el-input__inner"
                                  />
                                </div>
                              </div>
                              <div class="cog-field__placeholder cog-input">
                                <div class="cog-field__placeholder-inner">
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </section>
                  </div>

                  <div
                    class=""
                    style={{
                      display: "flex",
                      gap: "2rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    {/* <button type="submit" id="form1submit" class="d-none">
                      Submit
                    </button> */}
                    {/* <a
                      type="button"
                      href="#main_content"
                      style={{
                        color: "white",
                        textDecoration: "none",
                        backgroundColor: "#C24B23",
                        padding: "0rem .7rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setVal(3)}
                      class="el-button cog-button--secondary cog-button--navigation cog-button--back el-button--default cog-button"
                    >
                      <span>
                        <span class="cog-button__icon">
                     
                        </span>{" "}
                        <span class="cog-button__text">Reference Form</span>
                      </span>
                    </a> */}
                    <a
                      type="button"
                      onClick={handleForm}
                      style={{ color: "white", textDecoration: "none" }}
                      class="el-button cog-button--has-status cog-button--primary cog-button--navigation el-button--default cog-button cog-button--next cog-button"
                      data-next=""
                    >
                      <span>
                        <span class="cog-button__text">Next</span>{" "}
                        <span class="cog-button__icon">
                          <svg
                            viewBox="0 0 18 18"
                            focusable="false"
                            class="cog-icon cog-i-next"
                          >
                            <g>
                              <g class="cog-i-chevron">
                                <path
                                  stroke-linecap="square"
                                  d="M6.58 14.42L12 9 6.59 3.59"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </span>{" "}
                      </span>
                    </a>
                  </div>
                </div>
              }

              {/* <!--tab data 2--> */}
              {val === 3 && (
                <div
                  // data-page="2"
                  class="cog-page cog-wrapper cog-transition-ltr tabcontent"
                  id="Paris"
                  style={{ display: "block" }}
                >
                  <div class="cog-row">
                    <section
                      aria-labelledby="cog-449"
                      tabindex="-1"
                      class="cog-section cog-col cog-col--24 cog-section--heading cog-field--15"
                    >
                      <Verify
                        // setVarifyShow={setVarifyShow}
                        buyer={buyer}
                        seller={seller}
                        propertyAddress={propertyAddress}
                        purchasePrice={purchasePrice}
                        earnMoney={earnMoney}
                        cclosingDate={cclosingDate}
                        financing={financing}
                        deligencePeriod={deligencePeriod}
                        other={other}
                        buyerPrient={buyerPrient}
                        sellerPrient={sellerPrient}
                        buyerSignature={burl}
                        // sellerSignature={surl}
                        bdate={bdate}
                        // sdate={sdate}
                        logo={logo}
                        // footer={footer}
                        // exclusive={exclusive}
                        addClause={addClause}
                        email={email}
                        phone={phone}
                        nologo={noLogo}
                        sendEmail={sendEmail}
                        setVal={setVal}
                      />
                      {/* <Verify setVal={setVal} /> */}
                    </section>
                  </div>
                </div>
              )}

              {/* <!--tab data 3--> */}
              {val === 1 && (
                <div
                  // data-page="3"
                  class="cog-page cog-wrapper cog-transition-ltr tabcontent"
                  id="Tokyo"
                  style={{ display: "block" }}
                >
                  <div class="cog-row">
                    <section
                      aria-labelledby="cog-35"
                      tabindex="-1"
                      style={{ animation: "varify 1s linear" }}
                      class="cog-section cog-col cog-col--24 cog-section--heading cog-field--20"
                    >
                      <Static />
                    </section>
                  </div>

                  <div
                    class="cog-page__navigation"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <a
                      type="button"
                      href="#main_content"
                      onclick="openCity(event, 'Paris'),back_2_btn3(),pass9()"
                      class="el-button cog-button--secondary cog-button--navigation cog-button--back el-button--default cog-button"
                      // style=" text-decoration:none"
                      style={{
                        color: "white",
                        textDecoration: "none",
                        backgroundColor: "#C24B23",
                      }}
                      onClick={() => setVal(2)}
                    >
                      <span>
                        <span class="cog-button__icon">
                          {/* <svg
                            viewBox="0 0 18 18"
                            focusable="false"
                            class="cog-icon cog-i-back"
                          >
                            <g>
                              <g class="cog-i-chevron">
                                <path
                                  stroke-linecap="square"
                                  d="M11.18 3.59L5.77 9l5.42 5.42"
                                ></path>
                              </g>
                            </g>
                          </svg> */}
                          <span class="cog-button__text">Next</span>
                          <svg
                            viewBox="0 0 18 18"
                            focusable="false"
                            class="cog-icon cog-i-next"
                          >
                            <g>
                              <g class="cog-i-chevron">
                                <path
                                  stroke-linecap="square"
                                  d="M6.58 14.42L12 9 6.59 3.59"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </span>{" "}
                      </span>
                    </a>
                  </div>
                </div>
              )}

              {/* <!-- Button trigger modal --> */}

              <div class="cog-abuse cog-wrapper">
                <hr />
                <div
                  class="container"
                  //    style="padding-bottom:20px"
                >
                  {/* <div class="copyright">
                    © Copyright{" "}
                    <strong>
                      <span>Banana Block</span>
                    </strong>
                    . All Rights Reserved
                  </div> */}
                </div>
              </div>
            </div>

            {/* <div class="cog-loader">
        <div class="cog-loader__cog"></div>
    </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
