import React, { useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { toast } from "react-toastify";

export default function Verify({
  buyer,
  seller,
  propertyAddress,
  purchasePrice,
  earnMoney,
  cclosingDate,
  financing,
  deligencePeriod,
  other,
  buyerPrient,
  sellerPrient,
  buyerSignature,
  // sellerSignature,
  bdate,
  logo,
  sendEmail,
  // exclusive,
  addClause,
  email,
  phone,
  setVal,
}) {
  // Excel code
  // const tableRef = useRef(null);

  // -----------------------Formated Date------------------->
  const formatDate = (inputDate) => {
    // Parse the input date
    const parsedDate = new Date(inputDate);

    // Create arrays for month and month names
    const months = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];

    // Format the date
    const day = parsedDate.getDate() + 1;
    const month = months[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();

    return `${day} ${month}, ${year}`;
  };

  const inputDate = cclosingDate;
  const formattedDate = formatDate(inputDate);

  // -----------------------Formated Date------------------->
  // ----------------------Handle Submit----------------->

  const fhandleSubmit = (e) => {
    e.preventDefault();

    if (!email || !phone) {
      toast.error("Please fill all the fields!", {
        theme: "dark",
        position: "top-center",
      });
    } else {
      sendEmail();
    }
  };

  // <-------------Export in excel--------->

  return (
    <div className="varify-container">
      <div className="varify-box">
        <h3 style={{ textAlign: "center" }}>
          LETTER OF INTENT TO PURCHASE REAL ESTATE
        </h3>

        <span style={{ display: "flex", gap: "1.5rem" }}>
          Logo:
          {logo ? (
            <img
              src={logo}
              alt="..."
              style={{ borderRadius: ".5rem", width: "14rem", height: "10rem" }}
            />
          ) : (
            "No Logo"
          )}
        </span>

        <span>{cclosingDate && formattedDate}</span>
        <span>BUYER: {buyer}</span>
        <span>SELLER: {seller}</span>

        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{}}>
            This is a letter of intent only. It does not create a legally
            binding obligation on the parties or their brokers. This letter of
            intent is subject to agreement between the parties upon a formal
            written purchase and sale contract containing the terms and
            conditions the parties find acceptable. This letter of intent sets
            forth some of the general terms and conditions for a purchase and
            sale transaction to be entered into concerning the above described
            real property.
          </p>
        </span>
        <span>1. PROPERTY ADDRESS: {propertyAddress}</span>

        <span>2. PURCHASE PRICE: ${purchasePrice}.00</span>
        <span>3. EARNEST MONEY: ${earnMoney}.00</span>
        <span>4. CONTRACT CLOSING DATE: {formattedDate}</span>
        <span>5. FINANCING: {financing}</span>
        <span>6. DUE DILIGENCE PERIOD: {deligencePeriod}</span>
        <span>7. ADDITIONAL CLAUSES: {addClause}</span>
        <span>Other: {other}</span>
        <span>Buyer Print: {buyerPrient}</span>
        <span>Seller Print: {sellerPrient}</span>
        <span style={{ display: "flex", gap: "1.5rem" }}>
          Buyer Signature: <img src={buyerSignature} alt="" />
        </span>
        {/* <span style={{ display: "flex", gap: "1.5rem" }}>
          Seller Signature: 
          <img src={sellerSignature} alt="" />
        </span> */}
        <span>Date:{bdate}</span>
        <span>Email: {email}</span>
        <span>Phone: {phone}</span>
      </div>
      <div
        className="excel-button"
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          gap: "3rem",
          marginTop: "2rem",
          width: "100%",
        }}
      >
        <div class="cog-page__navigation" style={{ paddingBottom: "1rem" }}>
          <a
            type="button"
            href="#main_content"
            style={{
              color: "white",
              textDecoration: "none",
              backgroundColor: "#C24B23",
            }}
            onClick={() => setVal(2)}
            class="el-button cog-button--secondary cog-button--navigation cog-button--back el-button--default cog-button"
          >
            <span>
              <span class="cog-button__icon">
                <svg
                  viewBox="0 0 18 18"
                  focusable="false"
                  class="cog-icon cog-i-back"
                >
                  <g>
                    <g class="cog-i-chevron">
                      <path
                        stroke-linecap="square"
                        d="M11.18 3.59L5.77 9l5.42 5.42"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>{" "}
              <span class="cog-button__text">Back</span>
            </span>
          </a>

          <a
            type="submit"
            data-toggle="tooltip"
            data-placement="top"
            class="el-button cog-button--has-status cog-button--primary cog-button--navigation el-button--default cog-button cog-button--next"
            data-next=""
            onClick={fhandleSubmit}
            style={{
              color: "white",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <span>
              <span class="cog-button__text">Submit</span>{" "}
              <span class="cog-button__icon">
                <svg
                  viewBox="0 0 18 18"
                  focusable="false"
                  class="cog-icon cog-i-next"
                >
                  <g>
                    <g class="cog-i-chevron">
                      <path
                        stroke-linecap="square"
                        d="M6.58 14.42L12 9 6.59 3.59"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>{" "}
            </span>
          </a>
        </div>

        {/* {!email ? (
          <button disabled>Export in Excel</button>
        ) : (
          <ReactHTMLTableToExcel
            id="table-to-xls"
            className={`download-table-xls-button `}
            table="data-table"
            filename="data"
            sheet="data"
            buttonText="Export to Excel"
            ref={tableRef}
          />
        )} */}
      </div>

      <div className="table" style={{ display: "none" }}>
        <table id="data-table">
          <thead>
            <tr>
              <th>BUYER</th>
              <th>SELLER</th>
              <th>PROPERTY ADDRESS</th>
              <th>PURCHASE PRICE</th>
              <th>EARNEST MONEY</th>
              <th>CONTRACT CLOSING DATE</th>
              <th>FINANCING</th>
              <th>DUE DILIGENCE PERIOD</th>
              <th>ADDITIONAL CLAUSES</th>
              <th>Other</th>
              <th>Buyer Print</th>
              <th>Seller Print</th>
              <th>Buyer Date</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{buyer}</td>
              <td>{seller}</td>
              <td>{propertyAddress}</td>
              <td>${purchasePrice}</td>
              <td>${earnMoney}</td>
              <td>{formattedDate}</td>
              <td>{financing}</td>
              <td>{deligencePeriod}</td>
              <td>{addClause}</td>
              <td>{other}</td>
              <td> {buyerPrient}</td>
              <td>{sellerPrient}</td>
              <td>{bdate}</td>
              <td>{email}</td>
              <td>{phone}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
