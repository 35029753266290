import React from "react";
import "./style.css";

export default function Static() {
  return (
    <div className="static-container">
      {/* <img src="/blankletter.png" alt="Static" /> */}
      <h3>LETTER OF INTENT TO PURCHASE REAL ESTATE</h3>

      <div className="form">
        <p>BUYER:</p>
        <p>SELLER:</p>
        <p>
          This is a letter of intent only. It does not create a legally binding
          obligation on the parties or their brokers. This letter of intent is
          subject to agreement between the parties upon a formal written
          purchase and sale contract containing the terms and conditions the
          parties find acceptable. This letter of intent sets forth some of the
          general terms and conditions for a purchase and sale transaction to be
          entered into concerning the above described real property.{" "}
        </p>
        <p>1. PROPERTY ADDRESS:</p>
        <p>2. PURCHASE PRICE:</p>
        <p>3. EARNEST MONEY: 1% at completion of Due Diligence.</p>
        <p>
          4. CONTRACT CLOSING DATE: 45 days from completion of Due Diligence.
        </p>
        <p>5. FINANCING:</p>
        <p>
          6. DUE DILIGENCE PERIOD: 60 days from receipt of all available,
          requested Due Diligence materials.
        </p>
        <p>
          7. Other:
          {/* Exclusivity clause / Option to extend DD or Finance period
          (apply funds to close) / etc */}
        </p>
        <div className="prints">
          <p>Buyer Print:</p>
          <p>Seller Print:</p>
        </div>
        <div className="prints">
          <p>Buyer Signature</p>
          <p>Buyer Signature</p>
        </div>
        <div className="prints">
          <p>Date:</p>
          <p>Date:</p>
        </div>
      </div>
    </div>
  );
}
