import "./App.css";
// import ClientForm from "./pages/ClientForm";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Demo from "./pages/Demo";

function App() {
  return (
    <div className="AppContainer">
      <BrowserRouter>
      <Routes>
      
        <Route path="/" element={ <Demo/>}></Route>
      </Routes>
      <ToastContainer /> 
      </BrowserRouter>
      
    </div>
  );
}

export default App;

      