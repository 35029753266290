import React from "react";
import "./style.css";
import SignatureCanvas from "react-signature-canvas";
import { IoCloseCircle } from "react-icons/io5";

export default function Bsignature({
  setBuyerSignature,
  buyerSignature,
  setbShow,
  handleSignature,
}) {
  const handleClear = () => {
    buyerSignature.clear();
  };

  //   Handle Save
  const handleSave = () => {
    setbShow(false);
    handleSignature();
  };
  return (
    <div className="buyerSignature">
      <div className="sig-box" style={{ position: "relative" }}>
        <IoCloseCircle onClick={() => setbShow(false)} />
        <h3 style={{ color: "#fff", textShadow: "-1px 1px 0px #000" }}>
          Buyer Signature
        </h3>
        <SignatureCanvas
          ref={(data) => setBuyerSignature(data)}
          canvasProps={{ className: "sigCanvas" }}
          value={buyerSignature}
          onChange={(e) => setBuyerSignature(e.target.value)}
        />
        <div className="buttons" style={{ display: "flex", gap: "2rem" }}>
          <input
            type="button"
            style={{ width: "5rem" }}
            onClick={handleClear}
            value="Clear"
            className="input-btn"
          />

          <input
            type="button"
            style={{ width: "5rem" }}
            onClick={handleSave}
            value="Save"
            className="input-btn"
          />
          {/* <button type="button" style={{ width: "5rem" }} onClick={handleClear}>
            Clear
          </button>
          <button style={{ width: "5rem" }} onClick={handleSave}>
            Save
          </button> */}
        </div>
      </div>
    </div>
  );
}
